import { DataSource } from '@angular/cdk/collections';
import { Component, OnInit, Inject } from '@angular/core';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { UserService } from '../services/user.service';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CommunicationService } from '../services/communication.service';
import { Sort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
@Component({
  selector: 'app-visitor',
  templateUrl: './visitor.component.html',
  styleUrls: ['./visitor.component.scss'],
  providers: [DatePipe]
})



export class VisitorComponent implements OnInit {
  displayedColumns = ['firstName', 'lastName', 'rank', 'email','mobile','role','signin','signout','lastscan','lastLocation','_id'];
  // visitorListData:PeriodicElement[] = [];
  // dataSource: any;
  // displayedColumns: string[];
  isCheckData: boolean;
  broadcastSubs: any;
  visitorListData: any;
  searchVisitor: any = "";
  visitorListDataRes = [
    { firstname: 'jhone', lastname: "doe", rank: 'civ', email: "jahone@datifex.com", mobile: '0422836484', role: 'Admin', signin: 'Admin', signout: 'Admin', lastscan: 'Admin', qrcode: 'Admin',lastLocation:'deck',_id:12 },
    { firstname: 'William', lastname: "James", rank: 'civ', email: "william@datifex.com", mobile: '0422836484', role: 'Admin', signin: 'Admin', signout: 'Admin', lastscan: 'Admin', qrcode: 'Admin',lastLocation:'deck',_id:12  },
  ];
  edit: any;
  subscription: Subscription;
  key: any;
  id:any;
  constructor(private datePipe: DatePipe,private userService: UserService, private communicationServ: CommunicationService, private route: Router, private dialog: MatDialog) {
    this.subscription = this.userService.getReload().subscribe(reload => {
      var profile = reload.text;
      if (profile == 'reload') {
        this.visitorList();
      }
    });
  }


  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  ngOnInit() {
    this.visitorList()

  }
  sortData(sort: Sort) {
    const data = this.visitorListDataRes.slice();
    if (!sort.active || sort.direction === '') {
      this.visitorListData = data;
      return;
    }

    this.visitorListData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      console.log("data", sort.active);
      switch (sort.active) {

        case 'firstname':
          return compare(a.firstname, b.firstname, isAsc);
        case 'lastname':
          return compare(a.lastname, b.lastname, isAsc);
        case 'rank':
          return compare(a.rank, b.rank, isAsc);
        case 'email':
          return compare(a.email, b.email, isAsc);
        case 'mobile':
          return compare(a.mobile, b.mobile, isAsc);
        case 'role':
          return compare(a.role, b.role, isAsc);
        case 'signin':
          return compare(a.signin, b.signin, isAsc);
        case 'signout':
          return compare(a.signout, b.signout, isAsc);
        case 'lastscan':
          return compare(a.lastscan, b.lastscan, isAsc);
          case 'lastLocation':
            return compare(a.lastLocation, b.lastLocation, isAsc);
        default:
          return 0;
      }
    });
  }
  searchVisitorData() {
    var filterData = [];
    var filterUserKey = ['firstName', 'lastName', 'rank', 'role', 'signin', 'signout', 'lastscan']
    if (this.searchVisitor === '') {
      filterData = null;
      this.visitorListData = this.visitorListDataRes;
    } else {
      if (this.visitorListData.length > 0) {
        for (let i = 0; i < this.visitorListData.length; i++) {
          if (filterUserKey.length > 0) {
            filterUserKey.forEach((key) => {
              if (typeof this.visitorListData[i][key] === 'string' && typeof this.searchVisitor === 'string') {
                if (this.visitorListData[i][key].toString().toLowerCase().indexOf(this.searchVisitor.toLowerCase()) > -1) {
                  const found = filterData.some(el => el._id === this.visitorListData[i]['_id']);
                  if (!found) {
                    filterData.push(this.visitorListDataRes[i]);
                  }
                }
              }
            });
          }
        }
      }
      this.visitorListData = filterData;
    }
  }
  searchVisitorClear() {
    this.searchVisitor = "";
    this.visitorListData = this.visitorListDataRes;
    let filterdData = null;
  }
  openDialog(): void {
    this.dialog.open(VisitorPopup, {
      data: {
          id: '',
          dataList: this.visitorListDataRes
      },
    });
  }
  visitorList(){
    this.userService.getVisitorData().subscribe(element => {
      console.log("visitorList",element)
      this.visitorListDataRes =element// this.visitorListDataRes
        this.visitorListData = this.visitorListDataRes
        this.visitorListData = this.visitorListDataRes.slice();
    })
  }
  
  deletevisitor(id ,e){
      let width = '285px';
      let type = 'error';
      if(e == "deleteRegistation-confirm"){
         type = "deleteRegistation-confirm"
      }
      const dialogRef = this.dialog.open(VisitorPopup, {
        panelClass: 'full-width-dialog',
        width: width,
        data: { value: '', error: e, type: type },
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('aaaa', result)
        if(result =='delete-visitor-yes'){
        this.broadcastInfo({src:'material',event :'deleteVisitorConfirm'})
        this.userService.deleteVisitorData(id).subscribe(eleme =>{
          this.visitorList()
        })
        }
      });
  }
 
  editVisitorList(data, id){
    this.dialog.open(VisitorPopup, {
      data: {
        visitor: {
          id: '',
        },
        edit: {
          edit: data,
          id: id,
        },
      },
    });
  }
  showVisitorHistory(data, id){
    this.dialog.open(VisitorPopup, {
      data: {key:data,id:id}      
    });
  }
  getDateFormat(date) {
    return this.datePipe.transform(date, 'MMM d, y, h:mm:ss a');
  }
  refreshData(){
    this.visitorList();
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


@Component({
  selector: 'visitorAdd-popup',
  templateUrl: 'visitorAdd-popup.html',
  styleUrls: ['./visitor.component.scss'],
  providers: [DatePipe]
})
export class VisitorPopup implements OnInit {
  visitorFormGroup: FormGroup;
  visitorId: any;
  CreatevisitorButton = true;
  visitorHistory:any=false;
  qrCode: any ="";
  visitorListData: any;
  visitorListDataRes = [];
  searchVisitor:any="";
  displayedColumns = ['firstName', 'lastName', 'rank','role','scanLocation','scandatetime'];
  visitorList: any =[];
  userAlreadyExist: boolean = false;
  userExistData: any;
  constructor(public dialogRef: MatDialogRef<VisitorPopup>,@Inject(MAT_DIALOG_DATA) public data: VisitorComponent, private _formBuilder: FormBuilder, private userService : UserService,private dialog: MatDialog,private communicationServ: CommunicationService, public datePipe : DatePipe){
    this.visitorHistory= false;
    var visitor = data.edit ? data.edit : "";
    this.visitorList = data['dataList'] ? data['dataList'] : "";
    //var VisitorId = data.dataList ? data.dataList : "";
    var visitorEdit = visitor.edit ? visitor.edit : '';
    if (visitorEdit == "userEdit") {
      //this.visitorList = visitorList
      this.CreatevisitorButton = false;
      this.getvisitorData(visitor.id);
      this.visitorId = visitor.id;
    }
    console.log(visitor)
    if(data.key&&data.key=="visitorHistory"){
      this.visitorHistory = true;
      this.getVisitorList(data.id);
    }
  }

  ngOnInit(): void {
    
    this.visitorFormGroup =new FormGroup({
      'email': new FormControl('', [
        Validators.required,
       Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/)
      ]),
      "mobile": new FormControl('', [Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      rank :new FormControl('', Validators.required),
      role : new FormControl('', Validators.required),
      signin : new FormControl(''),
      signout: new FormControl(''),
      scan : new FormControl(''),
      qrcode : new FormControl(''),
      lastScanLocation: new FormControl(''),
      lastScanDateTime : new FormControl(''),
      visitorID: new FormControl(''),
    });

    // this.broadcastSubs = this.communicationServ.getInstance()
    // .subscribe((data: any) => {
    //   if(data.src =="materail"){
    //     if(data.event == "deleteVisitorConfirm"){
    //       this.openDiaglog('deleteRegistation-confirm')
    //     }
    //   }
    // })
  }
  getVisitorList(id:any){
    this.userService.getVisitorScanData(id).subscribe(element => {
      console.log("getVisitorList",element)
      this.visitorListDataRes =element// this.visitorListDataRes
      this.visitorListData = this.visitorListDataRes
      this.visitorListData = this.visitorListDataRes.slice();
    })
  }



  sortData(sort: Sort) {
    const data = this.visitorListDataRes.slice();
    if (!sort.active || sort.direction === '') {
      this.visitorListData = data;
      return;
    }

    this.visitorListData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      console.log("data", sort.active);
      switch (sort.active) {

        case 'firstname':
          return compare(a.firstname, b.firstname, isAsc);
        case 'lastname':
          return compare(a.lastname, b.lastname, isAsc);
        case 'rank':
          return compare(a.rank, b.rank, isAsc);
        case 'email':
          return compare(a.email, b.email, isAsc);
        case 'mobile':
          return compare(a.mobile, b.mobile, isAsc);
        case 'role':
          return compare(a.role, b.role, isAsc);
        case 'signin':
          return compare(a.signin, b.signin, isAsc);
        case 'signout':
          return compare(a.signout, b.signout, isAsc);
        case 'lastscan':
          return compare(a.lastscan, b.lastscan, isAsc);
        default:
          return 0;
      }
    });
  } 
  getDateFormat(date) {
    return this.datePipe.transform(date, 'MMM d, y, h:mm:ss a');
  }
  searchVisitorData() {
    var filterData = [];
    var filterUserKey =  ['firstName', 'lastName', 'rank', 'email','mobile','role','scanLocation','scandatetime'];
    if (this.searchVisitor === '') {
      filterData = null;
      this.visitorListData = this.visitorListDataRes;
    } else {
      if (this.visitorListData.length > 0) {
        for (let i = 0; i < this.visitorListData.length; i++) {
          if (filterUserKey.length > 0) {
            filterUserKey.forEach((key) => {
              if (typeof this.visitorListData[i][key] === 'string' && typeof this.searchVisitor === 'string') {
                if (this.visitorListData[i][key].toString().toLowerCase().indexOf(this.searchVisitor.toLowerCase()) > -1) {
                  const found = filterData.some(el => el._id === this.visitorListData[i]['_id']);
                  if (!found) {
                    filterData.push(this.visitorListDataRes[i]);
                  }
                }
              }
            });
          }
        }
      }
      this.visitorListData = filterData;
    }
  }
  searchVisitorClear() {
    this.searchVisitor = "";
    this.visitorListData = this.visitorListDataRes;
    let filterdData = null;
  }
  createVisitor(){
    var filterdata = this.visitorList.filter(elem => elem.email == this.visitorFormGroup.value.email || elem.mobile == this.visitorFormGroup.value.mobile)
    console.log(filterdata)
    this.userExistData = filterdata[0]
    if(this.visitorFormGroup.value && filterdata.length == 0){
      this.visitorFormGroup.value.email =this.visitorFormGroup.value.email.toLowerCase();      
      this.userService.addVistiorData(this.visitorFormGroup.value).subscribe(ele => {
        console.log(ele)
        this.dialog.closeAll();
        this.userService.sendReload("reload");
      })
      console.log(this.visitorFormGroup.value);
    } else {
      this.userExist('userAlreadyExist-confirm')
    }
  }

  getvisitorData(id){
    this.userService.getVisitorDataById(id).subscribe(
      (response) => {
        var repos = response;
        var visitorData = repos[0]
        console.log("test", visitorData)
        this.visitorFormGroup.patchValue(visitorData);
        this.qrCode = visitorData.qrcode;
        console.log("test1", this.visitorFormGroup)
      },
      error => {
        console.error('Request failed with error')
        console.log(error)
      })
  }
  
  UpdateVisitor() {    
    this.visitorFormGroup.value.email =this.visitorFormGroup.value.email.toLowerCase();
    this.userService.updateVisitorData(this.visitorId, this.visitorFormGroup.value)
      .subscribe((res) => {
        this.dialog.closeAll();
        this.userService.sendReload("reload");
      }, error => {
        console.error('There was an error!');
      })
  }
  closeClick(): void {
    this.dialogRef.close();
  }

  userExist(e){
    let width = '285px';
      let type = 'error';
      if(e == "userAlreadyExist-confirm"){
         type = "userAlreadyExist-confirm"
      }
      const dialogRef = this.dialog.open(VisitorPopup, {
        panelClass: 'full-width-dialog',
        width: width,
        data: { value: '', error: e, type: type },
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('aaaa', result)
        if(result =='user-already-exist'){
        // this.broadcastInfo({src:'material',event :'deleteVisitorConfirm'})
        this.userService.reSendQrCode(this.userExistData).subscribe(eleme =>{
          this.visitorList()
        })
        this.closeClick()
        }
      });
  }

}