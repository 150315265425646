import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpRequest } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';

import { from, Observable,throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private http: HttpClient, ) { }
  getConfig(url): Observable<any> {
      return this.http.get<any>(url)
        .pipe(
          retry(1),
          catchError(this.handleError)
        )
  }
    // Error handling 
    handleError(error) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
      } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.log(errorMessage);
      // return truel
      // window.alert(errorMessage);
      // this.errorMsg.next(error);
      return throwError(error);
    }
}
