import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChitService {
  accessToken: any;
  refershToken: any;
  Locations = ["Gate In", "Gate Out", "Deck 1", "Deck 2", "Deck 3", "Deck 4"];

  // local
  // baseUrl = 'http://localhost:3000/';
  // url: any = 'http://localhost:3000/chitmaster/';
  // urlOOD: any = 'http://localhost:3000/chitmaster_ood/';
  // urlVessel: any = 'http://localhost:3000/chitmaster_vessel/';
  // urlSchedule: any = 'http://localhost:3000/chitMasterSchedule/';
  // urlTagout: any = 'http://localhost:3000/tagoutmaster/';
  // urlRole: any = 'http://localhost:3000/tagoutRoles/';
  // contractorUrl: any = 'http://localhost:3000/contractor/';
  // locationMasterUrl: any = 'http://localhost:3000/locationMaster/';
  // tagShapeIdUpdate: any = "http://localhost:3000/TagShape/tagShapeId";
  // logBook = "http://localhost:3000/logBook/";
  // collectionlayoutData = "http://localhost:3000/Layout/collectionlayoutData";
  // baseURLSchedule = 'http://localhost:3000/chitMasterSchedule/';

  // live
  baseUrl = 'https://nodesdms.azurewebsites.net/'
  url: any = 'https://nodesdms.azurewebsites.net/chitmaster/';
  urlOOD: any = 'https://nodesdms.azurewebsites.net/chitmaster_ood/';
  urlSchedule: any = 'https://nodesdms.azurewebsites.net/chitMasterSchedule/';
  urlVessel: any = 'https://nodesdms.azurewebsites.net/chitmaster_vessel/';
  urlTagout: any = 'https://nodesdms.azurewebsites.net/tagoutmaster/';
  urlRole: any = 'https://nodesdms.azurewebsites.net/tagoutRoles/';
  contractorUrl: any = 'https://nodesdms.azurewebsites.net/contractor/';
  locationMasterUrl: any = 'https://nodesdms.azurewebsites.net/locationMaster/';
  tagShapeIdUpdate: any = "https://nodesdms.azurewebsites.net/TagShape/tagShapeId";
  logBook = "https://nodesdms.azurewebsites.net/logBook/";
  collectionlayoutData ="https://nodesdms.azurewebsites.net/Layout/collectionlayoutData";
  baseURLSchedule = 'https://nodesdms.azurewebsites.net/chitMasterSchedule/';

  private subject = new Subject<any>();

  constructor(private http: HttpClient) { }

  // user
  getUserData(): Observable<any> {
    return this.http.get(this.baseUrl + 'authaccess/');
  }

  // Supervisor Services
  getSupervisorData(): Observable<any> {
    return this.http.get(this.url)
  }
  getSupervisorDataList(id: string): Observable<any> {
    return this.http.get(this.url + id)
  }
  deleteSupervisor(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    // const body=JSON.stringify(data);
    return this.http.delete(this.url + id, { 'headers': headers })
  }
  addSupervisor(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.url, body, { 'headers': headers })
  }
  updateSupervisor(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.url + id, body, { 'headers': headers })
  }
  // Ood Schedule services

  getOodScheduleData(): Observable<any> {
    return this.http.get(this.urlSchedule)
  }
  getOodScheduleDataList(data): Observable<any> {
    // const options = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json'
    //   }),
    //   body: data
    // }
    const headers = { 'content-type': 'application/json' }
    return this.http.post(this.urlSchedule + data.id, data, { 'headers': headers })
    // return this.http.get(this.urlSchedule + id)
  }
  deleteOodSchedule(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    // const body=JSON.stringify(data);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    }
    return this.http.delete(this.urlSchedule + data.id, options)
  }
  addOodSchedule(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.urlSchedule, body, { 'headers': headers })
  }
  updateOodSchedule(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.urlSchedule + id, body, { 'headers': headers })
  }
  // Ood Schedule services

  getOodData(): Observable<any> {
    return this.http.get(this.urlOOD)
  }
  getOodDataList(id: string): Observable<any> {
    return this.http.get(this.urlOOD + id)
  }
  deleteOod(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    // const body=JSON.stringify(data);
    return this.http.delete(this.urlOOD + id, { 'headers': headers })
  }
  addOod(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.urlOOD, body, { 'headers': headers })
  }
  updateOod(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.urlOOD + id, body, { 'headers': headers })
  }

  // vessel services

  getVesselData(): Observable<any> {
    return this.http.get(this.urlVessel)
  }
  getVesselDataList(id: string): Observable<any> {
    return this.http.get(this.urlVessel + id)
  }
  deleteVessel(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    // const body=JSON.stringify(data);
    return this.http.delete(this.urlVessel + id, { 'headers': headers })
  }
  addVessel(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.urlVessel, body, { 'headers': headers })
  }
  updateVessel(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.urlVessel + id, body, { 'headers': headers })
  }

  // Tagout services

  getTagoutData(): Observable<any> {
    return this.http.get(this.urlTagout)
  }
  getTagoutDataList(id: string): Observable<any> {
    return this.http.get(this.urlTagout + id)
  }
  deleteTagout(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    // const body=JSON.stringify(data);
    return this.http.delete(this.urlTagout + id, { 'headers': headers })
  }
  addTagout(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.urlTagout, body, { 'headers': headers })
  }
  updateTagout(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.urlTagout + id, body, { 'headers': headers })
  }

  // Tagout services
  getRoles(): Observable<any> {
    return this.http.get(this.urlRole);
  }
  getUserRoles(id: string): Observable<any> {
    return this.http.get(this.urlRole + id);
  }
  getRole(id: string): Observable<any> {
    return this.http.get(this.urlRole + 'role/' + id);
  }
  addRole(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.urlRole, body, { 'headers': headers });
  }
  updateRole(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.urlRole + id, body, { 'headers': headers });
  }
  deleteRole(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    // const body=JSON.stringify(data);
    return this.http.delete(this.urlRole + id, { 'headers': headers });
  }

  // get profile data
  sendProfileData(message: string) {
    this.subject.next({ text: message });
  }
  getProfileData(): Observable<any> {
    return this.subject.asObservable();
  }

  //  Reload data
  sendReload(reload: string) {
    this.subject.next({ text: reload });
  }
  getReload(): Observable<any> {
    return this.subject.asObservable();
  }
  pullJsonData(url, data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.pullData(this.baseUrl + url, data).subscribe(data => {
        resolve(data)
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * post api call
  * * */
  pullData(url, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(url, body, { 'headers': headers })
  }

  //*** Contractor API***/
  addContractor(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.contractorUrl, body, { 'headers': headers })
  }
  updateContractor(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.contractorUrl + id, body, { 'headers': headers })
  }
  getContractorDet(): Observable<any> {
    return this.http.get(this.contractorUrl)
  }
  getContractorList(id: string): Observable<any> {
    return this.http.get(this.contractorUrl + id)
  }
  deleteContractor(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    return this.http.delete(this.contractorUrl + id, { 'headers': headers })
  }

  //*** Location Master API***/
  createLocation(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.locationMasterUrl, body, { 'headers': headers })
  }
  updateLocation(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.locationMasterUrl + id, body, { 'headers': headers })
  }
  getLocationList(): Observable<any> {
    return this.http.get(this.locationMasterUrl)
  }
  getLocation(id: string): Observable<any> {
    return this.http.get(this.locationMasterUrl + id)
  }
  deleteLocation(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    return this.http.delete(this.locationMasterUrl + id, { 'headers': headers })
  }

  updateTagShapeId(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.tagShapeIdUpdate, body, { 'headers': headers })
  }


  createLogBookData(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.logBook, body, { 'headers': headers });
  }

  deleteLogBook(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    return this.http.delete(this.logBook + id, { 'headers': headers });
  }

  getCollectonlayoutData(data) {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.collectionlayoutData, body, { 'headers': headers });
  }

  getScheduleData(): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    return this.http.get(this.baseURLSchedule, { 'headers': headers })
  }

  shedulerFilter(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.baseURLSchedule + 'filter/Shedulerfilter', body, { 'headers': headers })
  }
}
