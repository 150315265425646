import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../services/user.service';
import { ChitService } from '../services/chit.service';
import { DatePipe } from '@angular/common';
import { CommunicationService } from '../services/communication.service';
import { AuthGuardService } from '../services/auth.service';

export interface DialogData {
  data: string;
  error: any;
  type: any;
}

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss']
})
export class QrScannerComponent implements OnInit {
  locationList: any = [];
  Locations = [];
  selectedLocation = "Gate In";
  visitorData: any = {};
  layoutData: any;
  vessel: any;
  usersDetails: any = [];
  userLists: any = [];

  constructor(private authService: AuthGuardService, private communicationServ: CommunicationService, private userService: UserService, public dialog: MatDialog, public chitService: ChitService, private datePipe: DatePipe,) {
    this.Locations = chitService.Locations;
  }

  ngOnInit() {
    this.userList();
    this.getVessel();
    this.getLayoutList();
    this.getLocationList();
  }

  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  userList() {
    this.chitService.getUserData()
      .subscribe(
        (response) => {
          // console.log('response received', response)
          var repos = response;
          this.usersDetails = repos;
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }

  /* * * * *
* Get All location list from master
* * * * * */
  getLocationList() {
    this.chitService.getLocationList().subscribe((res) => {
      console.log('locationList', res);
      this.locationList = res;
    }, error => {
      console.error('There was an error!');
    });
  }

  /* * * * *
  * check Json or Not
  * * * * * */
  hasJsonStructure(str) {
    if (typeof str !== 'string') return false;
    try {
      const result = JSON.parse(str);
      const type = Object.prototype.toString.call(result);
      return type === '[object Object]'
        || type === '[object Array]';
    } catch (err) {
      return false;
    }
  }

  public onSuccess(data, action) {
    if (data) {
      action.stop();
      let date = new Date();
      var isJson = this.hasJsonStructure(data)
      if (isJson) {
        let obj = JSON.parse(data);

        if (obj.visitorID) {
          let location = this.selectedLocation.split(" ").join("").toLocaleLowerCase();
          obj['lastScanDateTime'] = date;
          obj['lastScanLocation'] = this.selectedLocation;
          // if (location == 'gatein') {
          //   obj['signin'] = date;
          //   // this.visitorSignIn(obj);
          // } else if (location == 'gateout') {
          //   obj['signout'] = date;
          //   // this.visitorSignOut(obj);
          // } else {
          //   // this.visitorScan(obj);
          // }
          this.visitorData = obj;

          if (obj.tagShapeId != "") {
            this.getOOD(obj);
            // this.sendNotification(obj);
            // this.updateTagShapeId(obj.tagShapeId);
          }
          this.openDialog("Qr-code Scanned Successfully !!");
          console.log("onSuccess ", obj)
        } else {
          this.openDialog("Invaild QR-code !!");
        }
      } else {
        this.openDialog("Invaild Data !!");
      }
    }
  }

  public onError(e): void {
    alert(e);
  }

  visitorSignIn(obj) {
    if (obj) {
      this.userService.visitorSignin(obj).subscribe(res => {
        console.log("visitorSignIn Sucess !!")
      })
    }
  }

  visitorSignOut(obj) {
    if (obj) {
      this.userService.visitorSignout(obj).subscribe(res => {
        console.log("visitorSignOut Sucess !!")
      })
    }
  }

  visitorScan(obj) {
    if (obj) {
      this.userService.visitorScan(obj).subscribe(res => {
        console.log("visitorScan Sucess !!")
      })
    }
  }

  updateTagShapeId(tagShapeId) {
    let objVisitorLocation, objVisitorLocationShape;
    let locationKey = "visitor_" + this.selectedLocation.split(" ").join('')
    let tagLocationIdx = this.locationList.findIndex(item => item.locationKey == locationKey);
    if (tagLocationIdx > -1) {
      objVisitorLocation = this.locationList[tagLocationIdx]['location'];
      objVisitorLocationShape = this.locationList[tagLocationIdx]['locationShape'] || "avatar2";
      let pushParams = {
        "parentMeshId": "element-envelope-59__CEROS_200_aft-9e5d5679-5eeb-41d7-83e0-46aa60be4b9b",
        "locationShape": objVisitorLocationShape || "avatar2",
        "position": objVisitorLocation || {
          "x": 0,
          "y": 0,
          "z": 0,
        },
        "tagShapeId": tagShapeId
      }
      return pushParams;
      // this.chitService.updateTagShapeId(pushParams).subscribe(res => {
      //   console.log("visitorScan Sucess !!", res)
      //   this.createLogBook();
      // })
    } else {
      return [];
    }

  }
  postFilterData: any = {
    "date": [],
    "Vessel": [],
    "type": [],
    "venue": "slms"
  }
  filterDate: any = new Date("2021-03-01");
  getLayoutList() {
    this.postFilterData['date'].push(this.datePipe.transform(this.filterDate, 'yyyy-MM-dd'));
    this.chitService.getCollectonlayoutData(this.postFilterData).subscribe(response => {
      console.log("getLayoutDatas ", response);
      this.layoutData = response['collectionData'];
    })

  }

  createLogBook() {
    let logBookLayout = this.layoutData.find(layout => layout.layoutName && layout.layoutName.toLowerCase() == "logbook");
    // let startTime = this.getTime(this.visitorData.signin)
    if (logBookLayout) {
      let obj = {
        "layoutId": logBookLayout['_id'] || '',
        "typeCollection": 'Visitor',
        "tagName": this.visitorData['firstName'] + " " + this.visitorData['lastName'],
        "description": this.visitorData.lastScanLocation + ', ' + this.visitorData.lastScanDateTime || '',
        "startDate": this.datePipe.transform(this.visitorData.Date, 'yyyy-MM-dd') || this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
        "endDate": '',
        "startTime": this.visitorData.Start || '',
        "endTime": this.visitorData.End || '',
        "formStatus": 'completed',
        "venue": 'slms'
      }
      return obj;
    }else{
      return [];
    }
  }

  getVessel() {
    this.chitService.getScheduleData()
      .subscribe(
        (response) => {
          var repos = response;
          var filterData = repos.filter(obj => obj.userId == this.authService['auth']['account']['userName'])
          if (filterData.length != 0) {
            this.vessel = filterData[0]['vessel'];
          }
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }

  getOOD(obj) {
    let filterData = { vessel: this.vessel, date: this.visitorData.Date }
    this.chitService.shedulerFilter(filterData).subscribe(response => {
      console.log("response", response);
      let filterData = response//.filter(obj => obj.role != 'OOD');
      console.log('ooDresp', response);
      var data = filterData
      if (data && data.length != 0) {
        data.forEach(ele => {
          var emailData = this.usersDetails.filter(element => element.email == ele.userId);
          if (emailData && data.length != 0) {
            var loginUserdata = {
              "fName": emailData[0]['First Name'],
              "lName": emailData[0]['Last Name'],
              "email": emailData[0].email,
              'status': 'inProgress',
              'Role': ele.role,
              'First Name': emailData[0]['First Name'],
              'defalutDelete': false
            }
          }
          this.userLists.push(loginUserdata);
        })
        console.log('loginUser', this.userLists);
        this.sendNotification(obj);
      }
    })
  }


  sendNotification(obj) {
    let visitorInfo = this.updateTagShapeId(obj.tagShapeId)
    let loogBookInfo = this.createLogBook()
    let loginUser = {
      "email": this.authService['auth']['account']['userName'],
      "fName": this.authService['auth']['account']['name']
    }
    let sendParams = {
      "scanInfo": obj,
      "visitorInfo": visitorInfo,
      "loogBookInfo": loogBookInfo,
      "AppName": "SDMS",
      "loginUser": loginUser,
      "visitorOOD": this.userLists,
      "socket_event": "visitor-notification"
    }
    this.broadcastInfo({ src: 'comment', event: 'userSocketSendInfo', data: sendParams });
  }

  getTime(dat) {
    let date = new Date(dat);
    let h: any = date.getHours();
    let m: any = date.getMinutes();
    h = h > 9 ? h : '0' + h;
    m = m > 9 ? m : '0' + m;
    return h + ':' + m;
  }

  /* * *
  * method for openDialog comp
  * * */
  openDialog(e): void {
    let width = '285px';
    let type = 'error';

    if (e == 'chartmodel') {
      type = 'chartmodel';
      width = '750px'
    }

    const dialogRef = this.dialog.open(PopupComponent, {
      panelClass: 'full-width-dialog',
      width: width,
      data: { value: '', error: e, type: type },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}

@Component({
  selector: 'popup-model',
  templateUrl: 'popup-model.html',
})
export class PopupComponent {
  constructor(
    public dialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  closeClick(): void {
    this.dialogRef.close();
  }

}